import React, { useState } from 'react';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';

import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';
import { Colors } from 'styles';
import { LoadingButton, SectionSubTitle } from 'components';
import { FormGroup, FormGroupProps, I18n } from 'core';
import CupsTooltipOferta from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipOferta';
import ButtonCsv from 'components/ButtonCsv/ButtonCsv';
import CommunitiesServiceIntance from 'services/remote/communities-service';
import { LoggingService } from 'services';

import customToast from 'components/CustomToast/CustomToast';
import { CommunityModel } from 'types/models/community';
import styles from './comunidades-detalle-csv.module.scss';

interface ENPropsI {
  communityId: string;
  currentLang: SelectItemI;
  hasCurva: string;
  title: string;
  textButton: string;
  afterSubmitAction?: CallableFunction;
  textButtonDownload: string;
  textTooltip: string;
  typeCurva: string;
  selectedCommunity: CommunityModel;
}
const ComunidadesDetalleCsv = ({
  communityId,
  afterSubmitAction,
  hasCurva,
  title,
  textButton,
  textButtonDownload,
  textTooltip,
  typeCurva,
  selectedCommunity,
}: ENPropsI): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [consumoAnualPromedio, setConsumoAnualPromedio] = useState<number>(
    selectedCommunity?.consumoAnualPromedio || 0,
  );

  const [loadingExportInforme, setLoadingExportInforme] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errors, setErrors] = useState({});
  const [mensajeError, setMensajeError] = useState({
    codigo: '',
    mensaje: '',
  });
  const [mensajeErrorActualCsv, setMensajeErrorActualCsv] = useState('');
  const validateForm = (): boolean => {
    if (!selectedFile) {
      setErrors({ file: 'Debe seleccionar un archivo.' });
      return false;
    }
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };

  const updateSubmitDisabled = (file: File | null): void => {
    if (!file) {
      setSubmitDisabled(true); // Deshabilitar si no hay archivo
    } else if (typeCurva === 'curva-consumo' || typeCurva === 'curva-generacion') {
      setSubmitDisabled(false); // Habilitar si hay archivo y el tipo de curva es válido
    } else {
      setSubmitDisabled(true); // Deshabilitar para otros casos
    }
  };

  const handleFileChange = (file: File | null): void => {
    setSelectedFile(file); // Actualiza el archivo seleccionado
    updateSubmitDisabled(file); // Actualiza inmediatamente el estado del botón
  };
  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (!selectedFile) {
        setMensajeError({
          codigo: 'ARCHIVO_NO_SELECCIONADO',
          mensaje: I18n.t('ComunidadesDetallePage.errors.archivoNoSeleccionado'),
        });
        return false;
      }

      const patchCommunityCurvaCsv =
        typeCurva === 'curva-generacion'
          ? await CommunitiesServiceIntance.patchCsvConfiguracionComunidades(communityId, selectedFile)
          : await CommunitiesServiceIntance.patchCsvCurvaDeConsumo(communityId, selectedFile);

      if (patchCommunityCurvaCsv === 200) {
        // Éxito
        const mensaje =
          typeCurva === 'curva-generacion'
            ? `${I18n.t('ComunidadesDetallePage.detailData.curva.subidaCorrectamente')}!`
            : `${I18n.t('ComunidadesDetallePage.detailData.curva.subidaCorrectamenteConsumo')}!`;
        customToast.success(mensaje);

        // Acción posterior si está definida
        if (afterSubmitAction) {
          afterSubmitAction();
        }

        // Limpia los errores y estados
        setMensajeError({ codigo: '', mensaje: '' });
        setSelectedFile(null);
      } else {
        // Error devuelto por el servidor
        setMensajeError(patchCommunityCurvaCsv);
      }
    } catch (error: any) {
      // Manejo de error en caso de fallo de solicitud
      console.error(error);
      setMensajeError(error);
    } finally {
      // Finaliza el estado de carga y permite nuevas interacciones
      setLoading(false);
      setSubmitDisabled(false);
    }

    return true;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { selectedFile });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { selectedFile, errors });
    }
  };

  const exportInforme = async (): Promise<void> => {
    setLoadingExportInforme(true);

    try {
      const ejecutarInforme =
        typeCurva === 'curva-generacion'
          ? await CommunitiesServiceIntance.descargarInformeComunidades(communityId)
          : await CommunitiesServiceIntance.descargarInformeConsumoComunidades(communityId);

      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${ejecutarInforme}`;
      a.textContent = 'download';
      a.download = `${new Date().toLocaleDateString()}_ExportData.csv`;
      a.click();
      a.remove();
    } catch (error: any) {
      setMensajeErrorActualCsv(error.response.data);
    } finally {
      setLoadingExportInforme(false);
    }
  };
  const formFieldsRight: Array<FormGroupProps> = [
    {
      label: `${I18n.t('ComunidadesDetallePage.detailData.labels.consumoAnualPromedio')} kWh`,
      type: 'number',
      id: 'consumoAnualPromedio',
      path: 'consumoAnualPromedio',
      name: 'consumoAnualPromedio',
      placeholder: '',
      value: consumoAnualPromedio,
    },
  ];

  function handleChange(path: string, value: any): void {
    selectedCommunity.setAttr(path, value);
    setConsumoAnualPromedio(value);
  }
  return (
    <SectionWrapper customClassname={styles.root}>
      <div className={styles.flex}>
        <DocumentsIcon height={20} width={20} fill={Colors.COLOR_BLACK} className={styles.DocumentsIcon} />

        <SectionSubTitle text={title} />
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        {hasCurva === 'si' ? (
          <div className={styles.datosPerfilConsumo}>
            <p>{I18n.t('ComunidadesDetallePage.detailData.curva.yaHayCsv')}</p>
          </div>
        ) : (
          ''
        )}
        {typeCurva === 'curva-generacion'
          ? null
          : formFieldsRight.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
                onChange={handleChange}
                maxLenght={200}
              />
            ))}

        <div className={styles.modalCsv}>
          <CupsTooltipOferta title={textTooltip} info={I18n.t('TooltipOferta.subtitle')} />

          <ButtonCsv className={styles.csv_button} onFileSelect={handleFileChange} />
          {mensajeError.codigo === '' ? null : (
            <div className={styles.errorMessage}>
              <p>{I18n.t(mensajeError.codigo)}</p>
              <p>{mensajeError.mensaje}</p>
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <LoadingButton type="submit" text={textButton} disabled={submitDisabled} loading={loading} />
        </div>
        {hasCurva === 'si' ? (
          <>
            <div className={styles.actions}>
              <LoadingButton
                type="submit"
                text={textButtonDownload}
                onClick={exportInforme}
                loading={loadingExportInforme}
                className={styles.loadingButtonSameSize}
              />
            </div>
            <div className={styles.actions}>
              {mensajeErrorActualCsv === '' ? null : (
                <div className={styles.errorMessage}>
                  <p>{mensajeErrorActualCsv}</p>
                </div>
              )}
            </div>
          </>
        ) : null}
      </form>
    </SectionWrapper>
  );
};

export default ComunidadesDetalleCsv;
