/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { I18n } from 'core';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
// import ConfiguracionMatcher from 'types/models/ConfiguracionMatcher';
import styles from './table-matcher.module.scss';

interface TableMatcherProps {
  headerMatcherHeader: string[];
  // Esto sera los miembros obtenidos que tenga es a configuracion
  miembrosMatcher: Array<any>;
  renderRow: (data: any, index: any) => JSX.Element;
}
const fullNameColumn = `${I18n.t('MatcherListPage.addMatcher.name').toLowerCase()} ${I18n.t(
  'MatcherListPage.addMatcher.apellidos',
).toLowerCase()}`;
const sortData = (data: Array<any>, columnName: string, direction: string) =>
  data.sort((a, b) => {
    let compareValue = 0;

    switch (columnName) {
      case fullNameColumn:
        compareValue = `${a.nombre} ${a.apellidos}`.localeCompare(`${b.nombre} ${b.apellidos}`);
        break;
      case I18n.t('MatcherListPage.addMatcher.consumo').toLowerCase():
        compareValue = a.consumoAnual - b.consumoAnual;
        break;
      case I18n.t('MatcherListPage.addMatcher.nif').toLowerCase():
        compareValue = a.nif.localeCompare(b.nif);
        break;
      case I18n.t('MatcherListPage.addMatcher.vip').toLowerCase():
        compareValue = a.miembroVip.localeCompare(b.miembroVip);
        break;
      case I18n.t('MatcherListPage.addMatcher.preferent').toLowerCase():
        compareValue = a.miembroPreferente.localeCompare(b.miembroPreferente);
        break;
      default:
        break;
    }
    return direction === 'asc' ? compareValue : -compareValue;
  });

const TableMatcherWithFilter = ({
  headerMatcherHeader,
  miembrosMatcher,
  renderRow,
}: TableMatcherProps): JSX.Element => {
  const [miebros, setMiembrosMatcher] = useState(miembrosMatcher); // Your initial miembrosMatcher array
  const [sortColumn, setSortColumn] = useState(''); // Columna actualmente ordenada
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento ('asc' o 'desc')
  useEffect(() => {
    // Aplicar el orden cada vez que los datos cambien
    let sortedData = [...miembrosMatcher];
    if (sortColumn) {
      sortedData = sortData(sortedData, sortColumn, sortDirection);
    }
    setMiembrosMatcher(sortedData);
  }, [miembrosMatcher, sortColumn, sortDirection]);
  // seleccionado, vip, preferente;
  const handleClick = (columnIndex: number) => {
    const columnName = headerMatcherHeader[columnIndex].toLowerCase();
    let direction: string;

    if (columnName === sortColumn) {
      direction = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      direction = 'asc';
    }
    setSortColumn(columnName);
    setSortDirection(direction);
  };
  useEffect(() => {
    // Update the miebros state when miembrosMatcher changes
    setMiembrosMatcher(miembrosMatcher);
  }, [miembrosMatcher]);
  const getSortIcon = (columnName: string) => {
    // Normalizamos los nombres de columnas ordenables
    const sortableColumns = [
      fullNameColumn.toLowerCase(), // Nombre Apellidos
      I18n.t('MatcherListPage.addMatcher.consumo').toLowerCase(), // Consumo
      I18n.t('MatcherListPage.addMatcher.nif').toLowerCase(), // Nif
      I18n.t('MatcherListPage.addMatcher.vip').toLowerCase(), // Vip
      I18n.t('MatcherListPage.addMatcher.preferent').toLowerCase(), // Preferente
    ];

    // Normalizamos el nombre de la columna actual
    const normalizedColumnName = columnName.toLowerCase();

    // Verificar si la columna es sortable
    if (sortableColumns.includes(normalizedColumnName)) {
      // Comparar con la columna actualmente ordenada (también normalizada)
      if (normalizedColumnName === sortColumn.toLowerCase()) {
        return sortDirection === 'asc' ? <span>&#9650;</span> : <span>&#9660;</span>;
      }
    }

    return null; // No mostrar icono para columnas no ordenables
  };

  return (
    <div className={styles.tableMatcher}>
      <table>
        <thead>
          <tr>
            {headerMatcherHeader.map((headerName: string, index: number) => (
              <th
                style={{
                  textAlign: ['Incluir', 'Nif', 'Vip', 'Preferente', 'Fuente', 'Curva', '% Perfil', 'Consumo'].includes(
                    headerName,
                  )
                    ? 'center'
                    : 'left',
                }}
                key={index}
                onClick={() => handleClick(index)}
              >
                {headerName}
                {getSortIcon(headerName)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {miebros ? (
            (() => {
              if (miebros && miebros.length > 0) {
                return miebros.map((rowData, index) => renderRow(rowData, index));
              }
              return (
                <tr className={styles.Sinboder}>
                  <td className={styles.nodata}>{I18n.t('noData')}</td>
                </tr>
              );
            })()
          ) : (
            <FullPageSyncLoader icon />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableMatcherWithFilter;
