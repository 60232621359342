import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { I18n } from 'core';
import TooltipOferta from 'components/tooltipOferta';
import styles from './CupsTooltip.module.scss';

const TooltipContent = (props: { info: string }): JSX.Element => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const { info } = props;

  return (
    <div className={styles.contentOferta}>
      <p>
        {I18n.t('TooltipOferta.title')} {previousYear} o {currentYear}.{info}
      </p>
    </div>
  );
};

const CupsTooltipOferta = (props: { title: string; info: string; show?: boolean }): JSX.Element => {
  const { title, info, show = false } = props;

  return (
    <div>
      <TooltipOferta
        cursor="help"
        direction={show ? 'bottom-with-info' : 'bottom'}
        content={<TooltipContent info={info} />}
      >
        <div className={styles.root}>
          <div className={styles.icon}>
            <InfoIcon />
          </div>
          <div>
            <p>{title}</p>
            {show && <p className={styles.consumoAnual}>{info}</p>}
          </div>
        </div>
      </TooltipOferta>
    </div>
  );
};

export default CupsTooltipOferta;
