import React, { useEffect, useState } from 'react';
import { LoadingButton, SectionTitle, Spinner, Toggle } from 'components';
import I18n from 'i18n-js';
import './fix-perfil-detalles.module.scss';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
import { PerfilConsumo } from 'types/models';
import MatcherPerfilesConsumoInstance from 'services/remote/MatcherPerfilesConsumoService';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { FormGroup, FormGroupProps } from 'core';
import ButtonCsv from 'components/ButtonCsv/ButtonCsv';
import customToast from 'components/CustomToast/CustomToast';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import { WattsConverterService } from 'services';
import CupsTooltipOferta from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipOferta';
import styles from './perfil-detalles.module.scss';

export const PerfilDetalle = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { perfilId },
    },
  } = props;
  const [perfilDeConsumo, setPerfilesDeConsumo] = useState<PerfilConsumo>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(false);
  const [mensajeError, setMensajeError] = useState({
    codigo: '',
    mensaje: '',
  });
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const [input, setInputs] = useState({
    nombre: '',
    descripcion: '',
  });

  const getPerfilesDeConsumoById = async () => {
    setLoading(true);

    try {
      const apiData = await MatcherPerfilesConsumoInstance.getPerfilesDeConsumoById(perfilId);
      setInputs({
        nombre: apiData?.nombre,
        descripcion: apiData?.descripcion,
      });
      setPerfilesDeConsumo(apiData);
    } catch (error) {
      toast.error(I18n.t('PerfilListPage.EditPerfilesConsumo_fetching_error'));
    }
    setLoading(false);
  };

  const formGroupUserDataLeft: Array<FormGroupProps> = [
    {
      label: I18n.t('Name'),
      type: 'text',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: input.nombre,
      placeholder: I18n.t('Name'),
      tabindex: 1,
    },
    {
      label: I18n.t('PerfilListPage.labels.descripcion'),
      type: 'text',
      id: 'descripcion',
      path: 'descripcion',
      name: 'descripcion',
      value: input.descripcion,
      placeholder: I18n.t('PerfilListPage.labels.descripcion'),
      tabindex: 1,
    },
  ];
  function handleChange(path: string, value: string): void {
    setInputs((prev) => ({
      ...prev,
      [path]: value,
    }));
  }
  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  const submitForm = async (): Promise<void> => {
    // cosas
    try {
      await MatcherPerfilesConsumoInstance.patchPerfilDeConsumoActualizacion(perfilId, input);
      if (selectedFile) {
        // Repasar
        const addCSV = await MatcherPerfilesConsumoInstance.patchPerfilDeConsumoCSV(perfilId, selectedFile);
        if (addCSV === 200) {
          customToast.success(I18n.t('PerfilListPage.UpdatePerfilesConsumo_fetching'));
          navigate('/tecnic/matcher/preferencias/perfiles-consumo');
        } else {
          setLoading(false);
          setMensajeError(addCSV);
        }
      } else {
        navigate('/tecnic/matcher/preferencias/perfiles-consumo');
      }
    } catch (error) {
      // llamar a MatcherErrorCsvService utils para trata de errores
      toast.error(I18n.t('PerfilListPage.UpdatePerfilesConsumo_fetching_error'));
    }
  };
  const exportInforme = async (): Promise<void> => {
    setLoadingExport(true);
    try {
      const ejecutarInforme = await MatcherPerfilesConsumoInstance.descargarInforme(Number(perfilId));
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${ejecutarInforme}`;
      a.textContent = 'download';
      a.download = `${new Date().toLocaleDateString()}_ExportData.csv`;
      a.click();
      a.remove();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExport(false);
    }
  };
  useEffect(() => {
    getPerfilesDeConsumoById();
  }, [perfilId]);
  return (
    <SectionWrapper>
      {loading ? (
        <div className={styles.loading}>
          <Spinner size={100} icon />
        </div>
      ) : (
        <>
          {perfilDeConsumo !== undefined ? (
            <div className={styles.root}>
              <div className={styles.form}>
                <div className={styles.posotionradio}>
                  <div className={styles.postflex}>
                    <SectionTitle text={I18n.t('PerfilListPage.titleDetalle')} />
                  </div>
                  <div className={styles.positionValidar}>{/* )} */}</div>
                </div>

                <div className={styles.containerForms}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      nombre: '',
                      descripcion: '',
                    }}
                    onSubmit={async (): Promise<void> => {
                      if (loadingExport === true) {
                        await exportInforme();
                      } else {
                        await submitForm();
                      }
                    }}
                  >
                    {() => (
                      <Form className={styles.form}>
                        <div className={styles.margenesInputModal}>
                          {formGroupUserDataLeft.map((formGroup: FormGroupProps) => (
                            <div key={formGroup.id} style={{ width: '100%' }}>
                              <FormGroup
                                key={formGroup.id}
                                label={formGroup.label}
                                type={formGroup.type}
                                id={formGroup.id}
                                path={formGroup.path}
                                name={formGroup.name}
                                placeholder={formGroup.placeholder}
                                value={formGroup.value}
                                onChange={handleChange}
                                options={formGroup.options}
                                disabled={formGroup.disabled}
                                tabindex={formGroup.tabindex}
                                error={formGroup.error}
                              />
                            </div>
                          ))}
                          <div className={styles.margenesInputModal}>
                            {perfilDeConsumo.infoDeConsumo.consumoAnual === 0 ? (
                              <>
                                <CupsTooltipOferta
                                  title={I18n.t('PerfilListPage.showPerfilConsumoCsv')}
                                  info={I18n.t('TooltipOferta.subtitle')}
                                />

                                <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />
                                {mensajeError.codigo === '' ? null : (
                                  <div className={styles.errorMessage}>
                                    <p>{I18n.t(mensajeError.codigo)}</p>
                                    <p>{mensajeError.mensaje}</p>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <div className={styles.datosPerfilConsumo}>
                                  <p className={styles.año}>{`${I18n.t('Year')} : ${
                                    perfilDeConsumo.infoDeConsumo.año
                                  }`}</p>
                                  <p className={styles.consumoAnual}>
                                    {`${I18n.t('PerfilListPage.ConsumoAnual')} : ${
                                      WattsConverterService.convertWatts(perfilDeConsumo.infoDeConsumo.consumoAnual).num
                                    }  ${
                                      WattsConverterService.convertWatts(perfilDeConsumo.infoDeConsumo.consumoAnual)
                                        .unitHour
                                    }`}
                                  </p>
                                </div>
                                <div className={styles.toogle_wrapper}>
                                  <p>{I18n.t('PerfilListPage.tituloCurva')}</p>
                                  <Toggle value={isActive} onChange={setIsActive} />
                                </div>
                                <div className={styles.toogle_wrapper} onClick={exportInforme}>
                                  <p className={styles.link_paragraph}>
                                    {I18n.t('PerfilListPage.addMatcher.exportGeneracioDescExist')}
                                  </p>
                                </div>
                                {isActive ? (
                                  <>
                                    <CupsTooltipOferta
                                      title={I18n.t('PerfilListPage.showPerfilConsumoCsv')}
                                      info={I18n.t('TooltipOferta.subtitle')}
                                    />

                                    <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />
                                    {mensajeError.codigo === '' ? null : (
                                      <div className={styles.errorMessage}>
                                        <p>{I18n.t(mensajeError.codigo)}</p>
                                        <p>{mensajeError.mensaje}</p>
                                      </div>
                                    )}
                                  </>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className={styles.positionBotonoes}>
                  <div className={styles.divBoton}>
                    <Button
                      type="button"
                      onClick={(): Promise<void> => navigate('/tecnic/matcher/preferencias/perfiles-consumo')}
                      variant="secondary"
                      text={I18n.t('Cancel')}
                    />
                    <LoadingButton type="submit" text={I18n.t('Save')} onClick={submitForm} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>{I18n.t('PerfilListPage.EditPerfilesConsumo_fetching_error')}</p>
          )}
        </>
      )}
    </SectionWrapper>
  );
};
