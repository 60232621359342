import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { LoadingButton, SectionTitle } from 'components';
import SectionWrapper from 'components/SectionWrapper/SectionWrapper';
// import { navigate } from 'navigation';
import ModalMatcher from 'components/ModalMatcher/ModalMatcher';
import { useToggle } from 'hooks/useToogle';
import ButtonCsv from 'components/ButtonCsv/ButtonCsv';
import { Form, Formik } from 'formik';
import { FormGroup, FormGroupProps } from 'core';
import MatcherPerfilesConsumoInstance from 'services/remote/MatcherPerfilesConsumoService';
import { PerfilConsumoAPI } from 'types/models/PerfilConsumo';
import CupsTooltipOferta from 'pages/SignUpPage/components/CupsTooltip/CupsTooltipOferta';
import { PerfilListRow } from '../perfil-list-row/perfil-list-row';
import styles from './perfil-list-table.module.scss';

interface PerfilListTableProps {
  data: Array<PerfilConsumoAPI>;
  solicitudID: string;
  onChange: CallableFunction;
}

export const PerfilListTable = (props: PerfilListTableProps): JSX.Element => {
  const { data, onChange } = props;
  const { t } = I18n;
  const [isSwitchOn, toggleSwitch] = useToggle();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [input, setInputs] = useState({
    nombre: '',
    descripcion: '',
  });
  const [mensajeError, setMensajeError] = useState({
    codigo: '',
    mensaje: '',
  });

  const formGroupUserDataLeft: Array<FormGroupProps> = [
    {
      label: I18n.t('Name'),
      type: 'text',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: input.nombre,
      placeholder: I18n.t('Name'),
      tabindex: 1,
    },
    {
      label: I18n.t('PerfilListPage.labels.descripcion'),
      type: 'text',
      id: 'descripcion',
      path: 'descripcion',
      name: 'descripcion',
      value: input.descripcion,
      placeholder: I18n.t('PerfilListPage.labels.descripcion'),
      tabindex: 1,
    },
  ];
  function handleChange(path: string, value: string): void {
    setInputs((prev) => ({
      ...prev,
      [path]: value,
    }));
  }

  const submitForm = async (): Promise<void> => {
    setLoading(true);

    // cosas
    try {
      const crearPerfilDeConsumo = await MatcherPerfilesConsumoInstance.postPerfilDeConsumo(input);
      if (selectedFile) {
        // Repasar
        const addCSV = await MatcherPerfilesConsumoInstance.patchPerfilDeConsumoCSV(
          crearPerfilDeConsumo.datos.id,
          selectedFile,
        );
        if (addCSV === 200) {
          onChange('CREAR PERFIL');
          setMensajeError({ codigo: '', mensaje: '' });
        } else {
          await MatcherPerfilesConsumoInstance.deletePerfilDeConsumo(crearPerfilDeConsumo.datos.id);
          setLoading(false);
          setMensajeError(addCSV);
        }
      } else {
        onChange('CREAR PERFIL');
      }
    } catch (error) {
      setLoading(true);
    }

    //
  };

  const handleCloseModal = () => {
    setInputs({
      nombre: '',
      descripcion: '',
    });
    setMensajeError({ codigo: '', mensaje: '' });
    setSelectedFile(null);
    toggleSwitch();
    setSubmitDisabled(true);

    // Add any other logic you need for closing the modal
  };

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  useEffect(() => {
    if (input.nombre.length !== 0 && input.descripcion.length !== 0 && selectedFile) {
      setSubmitDisabled(false);
    }
  }, [input.nombre, input.descripcion, selectedFile]);

  return (
    <div className={styles.root}>
      {isSwitchOn && (
        <ModalMatcher
          title={I18n.t('PerfilListPage.addMember')}
          body={
            <>
              <Formik
                enableReinitialize
                initialValues={{
                  nombre: '',
                  csv: '',
                }}
                onSubmit={submitForm}
              >
                {() => (
                  <Form className={styles.form}>
                    <div className={styles.margenesInputModal}>
                      {formGroupUserDataLeft.map((formGroup: FormGroupProps) => (
                        <div style={{ width: '100%' }}>
                          <FormGroup
                            key={formGroup.id}
                            label={formGroup.label}
                            type={formGroup.type}
                            id={formGroup.id}
                            path={formGroup.path}
                            name={formGroup.name}
                            placeholder={formGroup.placeholder}
                            value={formGroup.value}
                            onChange={handleChange}
                            options={formGroup.options}
                            disabled={formGroup.disabled}
                            tabindex={formGroup.tabindex}
                            error={formGroup.error}
                          />
                        </div>
                      ))}
                      <div className={styles.margenesInputModal}>
                        <CupsTooltipOferta
                          title={I18n.t('PerfilListPage.addPerfilConsumo')}
                          info={I18n.t('TooltipOferta.subtitle')}
                        />

                        <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />
                        {mensajeError.codigo === '' ? null : (
                          <div className={styles.errorMessage}>
                            <p>{I18n.t(mensajeError.codigo)}</p>
                            <p>{mensajeError.mensaje}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          }
          testbutton={I18n.t('Confirmar')}
          disabled={submitDisabled}
          // eslint-disable-next-line no-alert
          loading={loading}
          onClick={submitForm}
          onClick2={handleCloseModal}
        />
      )}

      <SectionWrapper>
        <div className={styles.titleWrapper}>
          <SectionTitle text={I18n.t('PerfilListPage.title')} />
          <div className={styles.loadingButtonFlex}>
            <LoadingButton
              className={styles.loadingButtonAddUser}
              type="submit"
              text={I18n.t('PerfilListPage.add')}
              onClick={toggleSwitch}
            />
          </div>
        </div>
        <table className={styles.table_wrapper}>
          {data.length > 0 ? (
            <>
              <thead>
                <tr>
                  <th>{t(`PerfilListPage.headers.name`)}</th>
                  <th>{t(`PerfilListPage.headers.fecha`)}</th>

                  <th aria-label="Detail" />
                </tr>
              </thead>
              <tbody>
                {data
                  .slice()
                  .sort((a, b) => {
                    if (a.infoDeConsumo && b.infoDeConsumo) {
                      return a.infoDeConsumo.consumoAnual - b.infoDeConsumo.consumoAnual;
                    }
                    return 0;
                  })
                  .map((perfilConsumo) => (
                    <PerfilListRow key={perfilConsumo.id} perfilConsumo={perfilConsumo} deleteOnChange={onChange} />
                  ))}
              </tbody>
            </>
          ) : (
            <div>{t(`PerfilListPage.perfilesConsumo_fetching_error`)}</div>
          )}
        </table>
      </SectionWrapper>
    </div>
  );
};
