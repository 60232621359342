import React, { useState } from 'react';
import cx from 'classnames';
import styles from './tooltipOferta.module.scss';

interface TooltipProps {
  children: any;
  delay?: number;
  direction?: 'top' | 'right' | 'bottom' | 'left' | 'bottom-with-info';
  content: string | Array<string> | React.ReactNode | JSX.Element;
  cursor?: 'pointer' | 'help';
}

const TooltipOferta = (props: TooltipProps): JSX.Element => {
  const { children, delay = 200, direction = 'top', content, cursor = 'pointer' } = props;
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div className={cx(styles.container)} style={{ cursor }} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <div className={cx(styles.tip, styles[`tip-${direction}`])}>{content}</div>}
    </div>
  );
};

export default TooltipOferta;
