import { AxiosResponse } from 'axios';
import configuration from 'config';
import { InscritosModel } from 'types/models/inscritos';
import apiService from './ApiService';

type PostInscrito = {
  idComunidad: number;
  nombre?: string;
  apellidos?: string;
  nif?: string;
  email?: string;
  cups?: string;
  domicilio?: string;
  poblacion?: string;
  provincia?: string;
  codigoPostal?: string;
  direccionCompleta?: string;
  telefono?: string;
  tipoConsumo: string;
  precioCompensacion: number;
  terminoEnergiaP1: number;
  terminoEnergiaP2: number;
  terminoEnergiaP3: number;
  terminoEnergiaP4: number;
  terminoEnergiaP5: number;
  terminoEnergiaP6: number;
};

type PostInscritoResponse = {
  datos: {
    id: string;
  };
};

export class InscritosService {
  readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async getCommunitiesEnrolled(): Promise<InscritosModel[]> {
    const url = `${this.apiUrl}/ce/inscritos/`;

    const response: AxiosResponse = await this.http.get(url);
    const result = new InscritosModel().deserializeArray(response.data.datos);
    return result;
  }

  public async getCommunitiesEnrolledCount(): Promise<number> {
    const response: AxiosResponse = await this.http.get(`${this.apiUrl}/ce/inscritos/count/`);
    return response.data.datos.numeroInscritosPendientes;
  }

  async addInscrito(inscrito: PostInscrito): Promise<PostInscritoResponse> {
    const url = `${this.apiUrl}/ce/inscritos/`;

    const response: AxiosResponse<PostInscritoResponse> = await this.http.post(url, inscrito);

    return response.data;
  }

  async getInscritosByComunity(id: string): Promise<InscritosModel[]> {
    const url = `${this.apiUrl}/ce/inscritos/?comunidad=${id}`;

    const response: AxiosResponse = await this.http.get(url);
    const result = new InscritosModel().deserializeArray(response.data.datos);
    return result;
  }

  async getInscritosByComunityInscritos(id: string, texto: string): Promise<InscritosModel[]> {
    const url = `${this.apiUrl}/ce/inscritos/?comunidad=${id}&buscar=${texto}`;

    const response: AxiosResponse = await this.http.get(url);
    const result = new InscritosModel().deserializeArray(response.data.datos);
    return result;
  }

  async getInscritosByComunityAllInscritos(texto: string): Promise<InscritosModel[]> {
    const url = `${this.apiUrl}/ce/inscritos/?buscar=${texto}`;

    const response: AxiosResponse = await this.http.get(url);
    const result = new InscritosModel().deserializeArray(response.data.datos);
    return result;
  }

  async getInscritosById(id: string): Promise<InscritosModel> {
    const url = `${this.apiUrl}/ce/inscritos/${id}`;

    const response: AxiosResponse = await this.http.get(url);
    const result = new InscritosModel().deserialize(response.data.datos);
    return result;
  }

  async deleteImage(idus: string, idimg: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/inscritos/${idus}/documento/${idimg}/`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async addGaleryImage(id: string, file: File, type: string): Promise<number> {
    const url = `${this.apiUrl}/ce/inscritos/${id}/documento/`;

    const imageData = new window.FormData();
    imageData.append('nombre', type);
    imageData.append('documento', file);

    const response: AxiosResponse<{ status: number }> = await this.http.post(url, imageData);

    return response.status;
  }

  async putInscrito(Inscrito: InscritosModel): Promise<string> {
    const url = `${this.apiUrl}/ce/inscritos/${Inscrito.id}/`;

    const InscritoToAPI = Inscrito.serialize();
    if (InscritoToAPI.margenMensual === '') {
      // eslint-disable-next-line no-param-reassign
      InscritoToAPI.margenMensual = '0';
    }
    const response: AxiosResponse<string> = await this.http.patch(url, InscritoToAPI);

    return response.data;
  }

  async putInscritoValidado(Inscrito: InscritosModel): Promise<any> {
    const url = `${this.apiUrl}/ce/inscritos/${Inscrito.id}/validar/`;

    const response: AxiosResponse<{ mensaje?: string }> = await this.http.post(url);
    if (response.data.mensaje) {
      return response.data.mensaje;
    }
    return response;
  }

  async sendInscritoToERP(Inscrito: InscritosModel): Promise<any> {
    const url = `${this.apiUrl}/ce/inscritos/${Inscrito.id}/enviar/`;

    const response: AxiosResponse<{ mensaje?: string }> = await this.http.post(url);
    if (response.data.mensaje) {
      return response.data.mensaje;
    }
    return response;
  }

  async putInscritoMatcher(Inscrito: InscritosModel): Promise<string> {
    const url = `${this.apiUrl}/ce/inscritos/${Inscrito.id}/?nocache=${new Date(Date.now())}`;

    const response: AxiosResponse<string> = await this.http.patch(url, Inscrito);

    return response.data;
  }

  async deleteInscrito(id: string): Promise<number> {
    const url = `${this.apiUrl}/ce/inscritos/${id}/?nocache=${new Date(Date.now())}`;

    const response: AxiosResponse<{ status: number }> = await this.http.delete(url);

    return response.data.status;
  }
}
const InscritosServicentance = new InscritosService();
export default InscritosServicentance;
